.button {
  width: 100%;
  background-color: var(--color-l);
  height: 42px;
  border-radius: 6px;
  /* border: solid 1px var(--color-l); */
  font-weight: 500;
  color: white;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  font-family: inherit; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  cursor: pointer;
  padding: 0px 6px;
}
.button:focus {
  outline: 0;
}
.button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 16px 16px 4px rgba(0, 0, 0, 0.1);
}
.button:disabled {
  filter: brightness(1.8);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@media (max-width: 767px) {
  .button {
    font-size: 14px !important;
  }
}

.outline {
  border: solid 1px var(--blue-d);
  background-color: transparent;
  color: var(--blue-d);
}
