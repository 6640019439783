.container {
  max-width: 400px;
  width: 100%;
  min-height: 210px;
  /* height: 210px; */
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  z-index: 101;
  left: 50%;
  transform: translateX(-50%);
  top: 10vh;
  overflow: hidden;
  animation: movement 0.1s ease-out;
  overflow-y: auto;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.header {
  width: 70%;
  margin: 0 auto;
  border-bottom: solid 1px #dcd7d7;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  color: rgba(15, 15, 15, 0.78);
  font-size: 1.2rem;
}
.modal__footer {
  display: flex;
  padding: 1rem 0.5rem;
}

@keyframes movement {
  from {
    left: 50%;
    transform: translate(-50%, 3rem) scale(0.9);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0) scale(1);
    opacity: 1;
  }
}
