@import "./../../Styles/mediaqueries.scss";
.loadingWrapper {
  position: absolute;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 210;
  background-color: white;
}

.logo {
  font-family: "Pacifico", cursive;
  font-size: 35px;
  color: #523de6;
  margin: 0 auto;
  text-align: center;
  svg {
    @include media("<tablet") {
      width: 12rem !important;
    }
  }
}
