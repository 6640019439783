@import "./../../Styles/mediaqueries.scss";
.logo {
  & > svg {
    width: 16rem;
    height: 4rem;
    @include media("<tablet") {
      width: 18rem;
    }
  }
}
