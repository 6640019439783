.inputField {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */

  width: 100%;
  height: 42px;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  outline: 0;
  padding: 12px 16px;
  color: var(--cloudy-clr);
}

.inputField:focus {
  border: solid 2px var(--color-l);
  color: black;
}

.errorField {
  border: solid 1px red !important;
}

.helperText {
  display: block;
  text-align: start;
  color: red;
  margin-bottom: 0.5rem;
}
