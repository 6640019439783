// HERE WE DEFINE GLOBALE VARIBALE
:root {
  --light-clr: #fafafa;
  --color-l: #1c2f90;
  --gray-dark: #424242;
  --gray-light2: #6e6e6e;
  --gray-light1: #b9bdc0;
  --gray-light0: #ebe9e9d2;

  --text-light-g: #d0dbe6;

  --red: #e54b4b;
  --blue-d: #1c2f90;
  --twitter-clr: #1da1f2;
  --twitter-clr1: #1d79f2;
  --radius: max(0px, min(8px, calc(calc(100vw - 4px) - 100%) * 9999)) / 8px;
  --shaddow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);

  --responsive-txt: min(8vw, 24px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-clr);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  // border-color: #e2e8f0;
  outline: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
}
/* USE THIS CLAS .styleScroll to use modren scroll. */
.styleScroll::-webkit-scrollbar {
  width: 10px;
  margin-top: 12px;
  height: 10px;
}

.styleScroll::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  display: none;
}

.styleScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background: #afafaf;
  background-clip: padding-box;
}

.fade-ellipse-text {
  overflow: hidden;
  position: relative;
  height: 2.6em; /* exactly three lines */
}
.fade-ellipse-text:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--light-clr) 50%
  );
}

.report-circle {
  position: relative;
  // height: 80px;
  // min-height: 80px;
  width: 80px;
  // min-width: 80px;
  border: transparent !important;
  border-radius: 50%;
  background: #1b2a96; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #1b2a96 0%,
    #7db9e8 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #1b2a96 0%,
    #7db9e8 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #1b2a96 0%,
    #7db9e8 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b2a96', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */
  display: flex;
  justify-content: center;
  align-items: center;
  .inner-circle {
    height: 92% !important;
    width: 92% !important;
    border: 3px solid white;
    border-radius: 50%;
    background: black;
    margin: auto;
  }
}

.text-center {
  text-align: center;
  color: var(--gray-light2);
}

.space-y > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1rem * (1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
}

.profile-institution-search-wrapper {
  .ais-SearchBox {
    width: 90%;
  }
  .ais-Hits {
    width: 100%;
  }

  .ais-Hits-list {
    flex-direction: column;
    margin: 0px;
    @extend .space-y;
    .ais-Hits-item {
      width: 100%;
      margin: 0px;
      border-radius: 6px;
      cursor: pointer;
      padding: 0.5rem;
    }
  }
}

.flex {
  display: flex;

  &-center {
    display: flex;
    justify-content: center;
  }
}

.space-bw {
  justify-content: space-between;
}
